import React, { useEffect, useState, useCallback } from "react"

import { dataStore, filterStore, locationStore, mapStore, pageStore } from "state/store-zustand"
import useContextTheme from 'components/utils/useContextTheme'
import usePageLoading from 'components/utils/usePageLoading'
import useMobileDetect from 'use-mobile-detect-hook'
const detectMobile = useMobileDetect()

import Header from "components/header"
import MapLayout from 'components/map/mapLayout'
import FetchEvents from 'components/utils/fetchEvents'
import FetchPlaces from 'components/utils/fetchPlaces'

import "styles/pages.scss"
import "styles/map_page.scss"

const MapPage = props => {
  let { slug, location } = props

  const slugFromPath = location.pathname.split('/')[2]
  const placeSlug = slug || (slugFromPath && slugFromPath !== "" ? slugFromPath : null)

  const pageLoader = usePageLoading()

  const editorialCategory = filterStore((state) => state.editorialCategory)
  const numPlacesToLoad = filterStore((state) => state.numPlacesToLoad)

  const hasLocation = locationStore((state) => state.hasLocation)
  const setZoomPrevious = locationStore((state) => state.setZoomPrevious)
  const shouldFetchEvents = mapStore((state) => state.shouldFetchEvents)
  const shouldFetchPlaces = mapStore((state) => state.shouldFetchPlaces)
  const zoomCurrent = mapStore((state) => state.zoomCurrent)
  const hasEditorial = editorialCategory != null

  const fetchEvents = !hasEditorial && shouldFetchEvents
  const fetchPlaces = shouldFetchPlaces

  const {
    compact,
    embedded,
    hasParams,
    isMobile,
    placeLayout,
    shouldLeavePage,
    showDetailsAsModal,
    setIsMobile,
    themeName
  } = pageStore((state) => state)

  const {
      hasPlace,
      events,
      eventCards,
      places,
      placesLoading,
      placeCurrent,
      setHasPlace,
      setPlaceCurrent,
      setPlaceFocused,
      setPlacesScrolling
    } = dataStore((state) => state)


  const [isClient, setClient] = useState(false)

  useEffect(() => {
    setClient(true)

    const handleResize = () => {
      const isMobileNew = detectMobile.isMobile()
      const width = typeof document !== 'undefined' ? document?.body?.clientWidth : null
      setIsMobile(isMobileNew || width < 800)
    }

    handleResize() // Initial check

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [detectMobile, setIsMobile])

  const themeContext = useContextTheme()
  const { themeClass } = themeContext

  const pageClass = `page map ${pageLoader} ${embedded ? 'embedded' : ''} ${compact ? 'compact' : ''} ${themeClass ? themeClass : ''}`

  if (!isClient && hasParams) {
    return null
  }

  const cardClickCallback = (slug, place) => {

    setHasPlace(true)
    setPlaceCurrent(place)
    setZoomPrevious(zoomCurrent)

    if (shouldLeavePage || !showDetailsAsModal) {
      // Show the place details on mobile on the same page in a modal
      // Go to the place page
      const isTitleCard = place?.properties?.is_title_card

      handlePlaceRoute({
        place: place,
        //leaveMap: isMobile,
        //shouldReplace: isMobile,
        location: location,
        theme: themeName,
        isTitleCard: isTitleCard
      })
    }
  }

  const useMobileLayout = placeLayout === 'map'

  return (
    <div className={pageClass}>
      {fetchEvents && <FetchEvents />}
      {fetchPlaces && <FetchPlaces numPlacestoLoad={numPlacesToLoad} />}
      <main>
        {!embedded && <Header />}
        <MapLayout
          cardClickCallback={cardClickCallback}
          slug={placeSlug}
          showLogo={embedded}
          hasPlace
          useMobileView={useMobileLayout}
          title='Explore the Map'
        />
      </main>
    </div>
  )
}

export default MapPage